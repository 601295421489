import React from "react"
import LayoutNoHeader from "../components/layoutWithoutHeader"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

export default function Confirmation({ data }) {
    debugger;
    const page = data.allContentfulZConfirmation.edges[0].node

    const {
        id,
        metaData,
        ccfHd,
        ccfTxt1,
        ccfBtn,
    } = page

    return (

        <LayoutNoHeader>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="robots" content="noindex" />
            </head>

            <section className="bg-lightgray">
                <div className="confirmation-container bg-lightgray">
                    <div className="row pt-5 pl-3 pb-3">
                        <a href={config.navigationURL_home}>
                            <img
                                src={require("../images/Logo/wunderloop_logo.svg")}
                                style={{ width: "60%" }}
                                alt="Wunderloop logo"
                            />
                        </a>
                    </div>
                    <div className="row align-items-center">
                        <div className="colBox8">
                            <div className="row pl-3">
                                <h2 className="align-top p-regular3 h2-drakgray">
                                    {ccfHd}
                                </h2>
                            </div>
                            <div className="row pl-3">
                                <h2 className="align-top p-regular4 text-blue2 pt-3">
                                    {ccfTxt1.ccfTxt1}
                                </h2>
                            </div>
                            <div className="text-center pt-4">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--outlinePurple2'
                                    buttonSize='btn--huge3'
                                    fullWidth
                                    href={config.navigationURL_home}

                                >
                                    {ccfBtn}
                                    <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="row bottomLine flex-nowrap pl-3 pt-5">
                        <a href={config.navigationURL_home}>
                            <h4 className="h4-login pr-4">
                                © Stripe
                            </h4>
                        </a>
                        <a href={config.navigationURL_contactSales}>
                            <h4 className="h4-login pr-4">
                                Contact
                            </h4>
                        </a>
                        <a href={config.navigationURL_privacy}>
                            <h4 className="h4-login pr-4">
                                Privacy & terms
                            </h4>
                        </a>
                    </div>
                </div>


            </section>
        </LayoutNoHeader >

    );
}



export const query = graphql`
  query($locale: String!) {
    allContentfulZConfirmation (
      filter: {
        node_locale: {eq: $locale},
        id: {in:["014ed7e7-a0f0-592a-b986-8711b0df5d67", "18028627-5fc4-54a7-80b1-4286ee5f5a54"]}
      }) {
      edges {
        node {
          id
          metaData {
            slug
            title
          }
          ccfHd
          ccfTxt1 {
            ccfTxt1
          }
          ccfBtn
        }
      }
    }
  }
`
